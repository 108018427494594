import React, {Component} from "react"
import {graphql, Link} from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import Layout from "../layouts"
import Masonry from 'react-masonry-component';
import {Motion, spring} from "react-motion";
import arrow from "../images/arrow.png"
import { Helmet } from "react-helmet"
const img1 = require('../images/android-icon-192x192.png');
const img2 = require('../images/favicon-32x32.png');
const img3 = require('../images/favicon-96x96.png');
const img4 = require('../images/favicon-16x16.png');

const _ = require(`lodash`)


const masonryOptions = {
    transitionDuration: 0
};

const imagesLoadedOptions = {background: '.my-bg-image-el'}


class ProjectTemplate extends Component {


    stripHtml(html){
        // Create a new div element
        var temporalDivElement = document.createElement("div");
        // Set the HTML content with the providen
        temporalDivElement.innerHTML = html;
        // Retrieve the text property of the element (cross-browser support)
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }


    render() {
        const post = this.props.data.wordpressWpProject

        if(typeof window === 'undefined') { return null; } let localization = window.location.pathname.split('/');

        console.log(post)

        let langs = ['pl', 'en', 'de'].map((element, index) => {

            return <li key={index}>
                {
                    element === 'pl' ?
                        <Link  activeStyle={{color: "#0033cc"}}  to={`/project/${post.slug}`}>{element}</Link> :
                        <Link  activeStyle={{color: "#0033cc"}}  to={`/${element}/project/${post.slug}`}>{element}</Link>
                }

            </li>
        });

        const images = post.acf.projectgallery.map((e, i) => {
            if (e.localFile) {
                return <li key={i} className={"project__mason"}><img src={e.localFile.childImageSharp.fluid.src}
                                                                     alt=""/></li>
            }
        });

        //English

        if (localization.includes("en")) {

            let p = _.find(this.props.data.wordpressWpProject.wpml_translations, l => l.locale === "en_US");

            if (!p) {
                return null;
            }

            return (
                <Layout>
                    <Helmet defaultTitle={"KazikKoduje"} titleTemplate={`%s | KazikKoduje`}>
                        <html lang="en" />
                        <meta name={"title"} content={p.post_title.substring(0, 50)}/>
                        <meta name={"description"} content={this.stripHtml(p.content).substring(0, 300)}/>
                        <meta property="og:title" content={p.post_title.substring(0, 50)} />
                        <meta property="og:description" content={this.stripHtml(p.content).substring(0, 300)} />
                        <meta property="og:url" content={localization} />
                        <meta property="og:type" content="website" />
                        <meta property="og:locale" content="en" />
                        <meta property="og:site_name" content={"KazikKoduje"} />
                        <meta property="og:image" content={(post.featured_media && post.featured_media.localFile) && post.featured_media.localFile.childImageSharp.fluid.src} />

                        {/*<meta name="twitter:card" content="summary" />*/}
                        {/*<meta name="twitter:site" content={twitter} />*/}
                    </Helmet>
                    {/* <div className="grid">
                        <ul className="langs col-12">
                            {langs}
                        </ul>
                    </div> */}
                    <Motion defaultStyle={{
                        x: 30,
                    }} style={{
                        x: spring(0, {stiffness: 55, damping: 10}),
                    }}>
                        {
                            style => (


                                <div className="project" style={{
                                    transform: `translateX(${style.x}px)`
                                }}>
                                    <article className="project__article grid">
                                        <div className="col-7 col-sm-12">
                                            {
                                                (post.featured_media && post.featured_media.localFile) &&
                                                <img className={"project__thumb"}
                                                     src={post.featured_media.localFile.childImageSharp.fluid.src}
                                                     alt=""/>
                                            }
                                        </div>
                                        <div className="project__meta col-5 col-sm-12">
                                            <h2 className="project__title">
                                                {p.post_title}
                                            </h2>
                                            <dl className={"project__dl"}>
                                                <dt>Client:</dt>
                                                <dd>{p.acf && p.acf.client}</dd>
                                                <dt>www:</dt>
                                                <dd>{p.acf && p.acf.www}</dd>
                                                <dt>Description:</dt>
                                                <dd>{p.acf && p.acf.description}</dd>
                                            </dl>
                                        </div>
                                        <div className="col-2-7 col-sm-1-12 project__text editor">
                                            <div dangerouslySetInnerHTML={{__html: p.content}}/>
                                        </div>
                                        <div className="col-2-10 col-sm-1-12">

                                            <Masonry
                                                className={'project__masonry'} // default ''
                                                elementType={'ul'} // default 'div'
                                                options={masonryOptions} // default {}
                                                disableImagesLoaded={true} // default false
                                                updateOnEachImageLoad={true} // default false and works only if disableImagesLoaded is false
                                                imagesLoadedOptions={imagesLoadedOptions} // default {}
                                            >
                                                {images}
                                            </Masonry>
                                        </div>
                                    </article>
                                </div>
                            )
                        }

                    </Motion>

                </Layout>
            )
        }

        //German

        if (localization.includes("de")) {

            let p = _.find(this.props.data.wordpressWpProject.wpml_translations, l => l.locale === "de_DE");

            if (!p) {
                return null;
            }

            return (
                <Layout>

                    <Helmet defaultTitle={"KazikKoduje"} titleTemplate={`%s | KazikKoduje`}>
                        <html lang="de" />
                        <meta name={"title"} content={p.post_title.substring(0, 50)}/>
                        <meta name={"description"} content={this.stripHtml(p.content).substring(0, 300)}/>
                        <meta property="og:title" content={p.post_title.substring(0, 50)} />
                        <meta property="og:description" content={this.stripHtml(p.content).substring(0, 300)} />
                        <meta property="og:url" content={localization} />
                        <meta property="og:type" content="website" />
                        <meta property="og:locale" content="de" />
                        <meta property="og:site_name" content={"KazikKoduje"} />
                        <meta property="og:image" content={(post.featured_media && post.featured_media.localFile) && post.featured_media.localFile.childImageSharp.fluid.src} />

                        {/*<meta name="twitter:card" content="summary" />*/}
                        {/*<meta name="twitter:site" content={twitter} />*/}
                    </Helmet>

                    {/* <div className="grid">
                        <ul className="langs col-12">
                            {langs}
                        </ul>
                    </div> */}
                    <Motion defaultStyle={{
                        x: 30,
                    }} style={{
                        x: spring(0, {stiffness: 55, damping: 10}),
                    }}>
                        {
                            style => (


                                <div className="project" style={{
                                    transform: `translateX(${style.x}px)`
                                }}>
                                    <article className="project__article grid">
                                        <div className="col-7 col-sm-12">
                                            {
                                                (post.featured_media && post.featured_media.localFile) &&
                                                <img className={"project__thumb"}
                                                     src={post.featured_media.localFile.childImageSharp.fluid.src}
                                                     alt=""/>
                                            }
                                        </div>
                                        <div className="project__meta col-5 col-sm-12">
                                            <h2 className="project__title">
                                                {p.post_title}
                                            </h2>
                                            <dl className={"project__dl"}>
                                                <dt>Kunde:</dt>
                                                <dd>{p.acf && p.acf.client}</dd>
                                                <dt>www:</dt>
                                                <dd>{p.acf && p.acf.www}</dd>
                                                <dt>Beschreibung:</dt>
                                                <dd>{p.acf && p.acf.description}</dd>
                                            </dl>
                                        </div>
                                        <div className="col-2-7 col-sm-1-12 project__text editor">
                                            <div dangerouslySetInnerHTML={{__html: p.content}}/>
                                        </div>
                                        <div className="col-2-10 col-sm-1-12">

                                            <Masonry
                                                className={'project__masonry'} // default ''
                                                elementType={'ul'} // default 'div'
                                                options={masonryOptions} // default {}
                                                disableImagesLoaded={true} // default false
                                                updateOnEachImageLoad={true} // default false and works only if disableImagesLoaded is false
                                                imagesLoadedOptions={imagesLoadedOptions} // default {}
                                            >
                                                {images}
                                            </Masonry>
                                        </div>
                                    </article>
                                </div>)}
                    </Motion>
                </Layout>
            )
        }

        //Polish

        return (
            <Layout>
                <Helmet defaultTitle={"KazikKoduje"} titleTemplate={`%s | KazikKoduje`}>
                    <html lang="pl" />
                    <meta name={"title"} content={post.yoast_meta.yoast_wpseo_title}/>
                    <meta name={"description"} content={post.yoast_meta.yoast_wpseo_description}/>
                    <meta property="og:title" content={post.yoast_meta.yoast_wpseo_title} />
                    <meta property="og:description" content={post.yoast_meta.yoast_wpseo_description} />
                    <meta property="og:url" content={localization} />
                    <meta property="og:type" content="website" />
                    <meta property="og:locale" content="pl" />
                    <meta property="og:site_name" content={"KazikKoduje"} />
                    <meta property="og:image" content={(post.featured_media && post.featured_media.localFile) && post.featured_media.localFile.childImageSharp.fluid.src} />

                    {/*<meta name="twitter:card" content="summary" />*/}
                    {/*<meta name="twitter:site" content={twitter} />*/}
                </Helmet>
                {/* <div className="grid">
                    <ul className="langs col-12">
                        {langs}
                    </ul>
                </div> */}
                <Motion defaultStyle={{
                    x: 30,
                }} style={{
                    x: spring(0, {stiffness: 55, damping: 10}),
                }}>
                    {
                        style => (


                            <div className="project" style={{
                                transform: `translateX(${style.x}px)`
                            }}>
                                <article className="project__article grid">
                                    <div className="col-7 col-sm-12">
                                        {
                                            (post.featured_media && post.featured_media.localFile) &&
                                            <img className={"project__thumb"}
                                                 src={post.featured_media.localFile.childImageSharp.fluid.src} alt=""/>
                                        }
                                    </div>
                                    <div className="project__meta col-5 col-sm-12">
                                        <h2 className="project__title">
                                            {post.title}
                                        </h2>
                                        <dl className={"project__dl"}>
                                            <dt>Klient:</dt>
                                            <dd>{post.acf && post.acf.client}</dd>
                                            <dt>www:</dt>
                                            <dd>{post.acf && post.acf.www}</dd>
                                            <dt>Opis:</dt>
                                            <dd>{post.acf && post.acf.description}</dd>
                                        </dl>
                                    </div>
                                    <div className="col-2-7 col-sm-1-12 project__text editor">
                                        <div dangerouslySetInnerHTML={{__html: post.content}}/>
                                    </div>
                                    <div className="col-2-10 col-sm-1-12">

                                        <Masonry
                                            className={'project__masonry'} // default ''
                                            elementType={'ul'} // default 'div'
                                            options={masonryOptions} // default {}
                                            disableImagesLoaded={true} // default false
                                            updateOnEachImageLoad={true} // default false and works only if disableImagesLoaded is false
                                            imagesLoadedOptions={imagesLoadedOptions} // default {}
                                        >
                                            {images}
                                        </Masonry>
                                    </div>
                                </article>
                            </div>)}
                </Motion>
            </Layout>
        )
    }
}

ProjectTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default ProjectTemplate

export const pageQuery = graphql`
    query($id: String!) {
        wordpressWpProject(id: { eq: $id }) {
            title
            content
            slug
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
                yoast_wpseo_canonical
            }
            wpml_translations {
                locale
                post_title
                content
                excerpt
                acf {
                    client
                    www
                    description
                }
            }
            acf {
                client
                www
                description
                projectgallery {
                    id
                    source_url
                    localFile {
                        childImageSharp {
                            fluid(quality: 50) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(quality: 50) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
            }
        }
    }
`